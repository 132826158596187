/* Theme Variables */
:root[data-theme="light"] {
  --bg-color: #f4f4f9;
  --text-color: #333;
  --input-border: #ddd;
  --input-focus: hsl(120, 100%, 27%);
  --progress-circle: rgb(76, 175, 80);
  --progress-text: rgb(0, 8, 0);
  --generate-button: hsl(120, 100%, 27%);
  --summary-border: hsl(0, 0%, 80%);
  --modal-bg: white;
  --modal-overlay: rgba(0, 0, 0, 0.5);
  --link-color: #271baf;
  --footer-color: #666;
  --card-bg: #ffffff;
  --card-shadow: rgba(0, 0, 0, 0.1);
  --error-color: hsl(0, 91%, 48%);
}

:root[data-theme="dark"] {
  --bg-color: #1a1a1a;
  --text-color: #e0e0e0;
  --input-border: #404040;
  --input-focus: #008a00;
  --progress-circle: #008a00;
  --progress-text: #e0e0e0;
  --generate-button: hsl(120, 100%, 27%);
  --summary-border: #404040;
  --modal-bg: #2d2d2d;
  --modal-overlay: rgba(0, 0, 0, 0.7);
  --link-color: hsl(245, 73%, 67%);
  --footer-color: #888;
  --card-bg: #2d2d2d;
  --card-shadow: rgba(0, 0, 0, 0.3);
  --error-color: hsl(0, 89%, 70%);
}

/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  text-align: center;
  margin: 0;
  padding: 0;
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

input[type="password"] {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid var(--input-border);
  border-radius: 5px;
  transition: border-color 0.3s ease;
  background-color: var(--card-bg);
  color: var(--text-color);
}

input[type="password"]:focus {
  border-color: var(--input-focus);
  outline: none;
}

/* Button Styles */
button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  transform: translateY(-2px);
}

.submit-button {
  width: 100%;
  max-width: 120px;
  background-color: hsl(120, 100%, 27%);
  color: white;
  margin-top: 16px;
}

.submit-button:hover {
  background-color: hsl(120, 100%, 24%);
}

.logout-button {
  background-color: hsl(0, 91%, 48%);
  color: white;
}

.logout-button:hover {
  background-color: hsl(0, 91%, 45%);
}

.generate-schedule-button {
  background-color: var(--generate-button);
  color: white;
  padding: 15px 20px;
  font-size: 15px;
}

.generate-schedule-button:hover {
  background-color: hsl(120, 100%, 24%);
}

/* Link Styles */
a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modal-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--modal-bg);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  text-align: center;
  color: var(--text-color);
}

.modal-button {
  background-color: hsl(120, 100%, 27%);
  color: white;
  margin: 10px;
}

.modal-button:hover {
  background-color: hsl(120, 100%, 24%);
}

.modal-button:first-of-type {
  background-color: hsl(0, 91%, 48%);
}

.modal-button:first-of-type:hover {
  background-color: hsl(0, 91%, 45%);
}

/* Footer Styles */
footer {
  color: var(--footer-color);
}

footer a {
  color: var(--link-color);
}

footer a:hover {
  text-decoration: underline;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.fix-width {
  max-width: 350px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
}

.para-container {
  max-width: 300px;
  margin: 20px auto;
  padding: 30px;
  text-align: left;
  font-family: 'Arial', sans-serif;
  background-color: var(--card-bg);
  border-radius: 12px;
  box-shadow: 0 4px 25px var(--card-shadow);
  color: var(--text-color);
}

.para-container ul {
  list-style-type: none;
  margin-bottom: 25px;
  padding: 0;
}

.para-container li {
  margin-bottom: 10px;
  font-size: 16px;
}

/* Theme Toggle Button Styles */
.theme-toggle {
  position: fixed;
  top: 15px;
  right: 15px;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 50%;
  background: var(--card-bg);
  border: 1px solid var(--input-border);
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1000;
  font-size: 14px;
  box-shadow: 0 2px 5px var(--card-shadow);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease;
}

.theme-toggle.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.theme-toggle.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
}

.theme-toggle:hover {
  transform: scale(1.1);
  background-color: var(--text-color);
  color: var(--bg-color);
}

.release-note {
  margin: 20px auto 10px;
  padding: 8px 15px;
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  text-align: center;
  font-size: 0.9rem;
  color: var(--text-color);
  max-width: 350px;
}

.new-badge {
  background: #ff4757;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 8px;
  font-weight: bold;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}