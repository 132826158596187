.schedule-container {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 42px;
    padding: 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    background-color: var(--card-bg);
    border-radius: 10px;
    box-shadow: 0 0 20px var(--card-shadow);
    transition: all 0.3s ease;
}

.schedule-title {
    font-size: 20px;
    margin-bottom: 20px;
    color: var(--text-color);
    font-weight: bold;
    padding: 8px 8px 0px 8px;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center; /* Center the items */
}

.input-field {
    padding: 12px 20px;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    font-size: 16px;
    width: 200px;
    height: 42px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    background-color: var(--card-bg);
    color: var(--text-color);
}

.date-input,
.target-input {
    width: 200px;
    height: 42px;
}

.input-field:focus {
    border-color: var(--input-focus);
}

.date-input::placeholder {
    color: var(--footer-color); /* Placeholder text color */
}

.generate-button {
    background-color: var(--generate-button);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease, transform 0.2s ease;
    margin-bottom: 10px;
}

.generate-button:hover {
    background-color: var(--input-focus);
    opacity: 0.9;
    transform: translateY(-2px);
}

.error-message {
    color: var(--error-color);
    margin-bottom: 30px;
}

.schedule-table-container {
    padding: 0 0px; /* Add padding around the table */
    margin-top: 20px; /* Add margin at the top to separate from the form */
    box-sizing: border-box; /* Ensure padding is included in the width */
}

.schedule-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 0 15px var(--card-shadow);
}

.schedule-table th,
.schedule-table td {
    border: 1px solid var(--input-border);
    padding: 12px;
    font-size: 14px;
    transition: all 0.3s ease;
}

.schedule-table th {
    background-color: var(--input-focus);
    color: white;
    font-weight: bold;
}

.schedule-table td {
    background-color: var(--card-bg);
    color: var(--text-color);
}

.schedule-table tbody tr:hover {
    background-color: var(--modal-bg);
}

.loading {
    font-size: 18px;
    color: var(--text-color);
}

/* Responsive Design */
@media (max-width: 600px) {
    .schedule-container {
        padding: 10px;
    }

    .schedule-title {
        font-size: 20px;
    }

    .input-field {
        font-size: 14px;
        padding: 8px;
    }

    .generate-button {
        font-size: 14px;
        margin: 4px 2px;
        padding: 8px 16px;
    }

    .schedule-table th,
    .schedule-table td {
        font-size: 12px;
        padding: 8px;
    }

    .form-container {
        gap: 5px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

.manual-target-container {
    margin: 10px 0;
    background-color: var(--card-bg);
    color: var(--text-color);
}